import React, { useState, useContext, useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/SEO';
import Header from '../components/Header';
import Button from '../components/Button';
import Footer from '../components/Footer';
import ColorsGlazesShapes from '../components/ColorsGlazesShapes';
import DualImageHover from '../components/DualImageHover';
import DesignCustomization from '../components/DesignCustomization'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { ContextLoader } from '../context';
import ImageSlider from '../components/ImageSlider';
import ImageSliderSwiperjs from '../components/ImageSliderSwiperjs';
import Picture from '../components/Picture';

const ProductCategoryDetails = ({ data }) => {

	// to show .onScreen animations for button/ footer=images etc..otherwise they will be hidden due to opacity 0
	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const category = data.prismicProductCategory.data;
	const productCategoryUid = data.prismicProductCategory.uid;
	const productId = productCategoryUid
	// console.log(category)
	return (
		<div>
			<SEO title={category.meta_title.text} description={category.meta_description.text} keywords={category.meta_keywords.text} />
			<Header currentPage="article" navigation={prismicNavigation} footer={prismicFooter} />
			<div className="ProductCategories">
				<div className="ProductCategories__Item">

					<div className="ProductCategories__TitleSection">
						<h1 className="ProductCategories__Title">
							{category.overview_title?.text}
						</h1>
						<div className="ProductCategories__TitleDescription">
							<RichText render={category.title_description?.raw} />
						{category.download_center_button &&  
							(	<Button className="ProductCategories__TitleButton"
										link={"/download_center"}
										modifier="big">
									Download Center
								</Button>
							)
						}
						</div>
					</div>
					<div className="ProductCategories__SubHeadingSection">
						<div className="ProductCategories__SubHeading">
							{category.subheading?.text}
						</div>
						<div className="ProductCategories__SubHeadingDescription">
							<RichText render={category.subheading_description?.raw} />
						</div>
					</div>

					<div className="ProductCategories__ItemContent">
						{category.body.map((slice, sliceIndex) => {
							if (slice.slice_type === 'paragraphs') {
								return (
									<div
										className="ProductCategories__Section ProductCategories__Paragraphs"
										key={productId + '_' + slice.id}
									>
										<RichText render={slice.primary.paragraph.raw} />
									</div>
								)
							}
							else if (slice.slice_type === 'full_width_image') {
								const image = slice.primary.image1;
								return (
									<div
										className="ProductCategories__Section ProductCategories__FullWidthImage"
										key={productId + '_' + slice.id}
									>
										<Picture
											src={image.fluid.src}
											srcSet={image.fluid.srcSet}
											alt={image.alt}
											sizes="94.44vw"
										/>
									</div>
								)
							}
							else if (slice.slice_type === 'button') {
								return (
									<div
										className="ProductCategories__Section ProductCategories__Button"
										key={productId + '_' + slice.id}
									>
										<Button
											link={slice.primary.link.url}
											modifier="big"
										>
											{slice.primary.text.text}
										</Button>
									</div>
								)
							}
							else if (slice.slice_type === 'side_by_side_image') {
								const data = slice.primary;
								const leftSlides = [{ image: data.image_left.fluid.src }];
								if (data.image_left_2.fluid.src !== data.image_left.fluid.src) {
									leftSlides.push({ image: data.image_left_2.fluid.src})
								}

								const rightSlides = [{ image: data.image_right.fluid.src }];
								if (data.image_right_2.fluid.src !== data.image_right.fluid.src) {
									rightSlides.push({ image: data.image_right_2.fluid.src})
								}
									
								console.log("data.image_left", data.image_left.fluid.src);
								return (
									<div
										className="ProductCategories__Section ProductCategories__SideBySideImage"
										key={productId + '_' + slice.id}
										// onClick={handleClickOnSideBySideImage}
									>
										<div className="ProductCategories__SideBySideImageContainer">
											<span className="ProductCategories__SideBySideImageCaptionTop">
												{data.image_left_top_caption?.text}
											</span>
											<span className="ProductCategories__SideBySideImageCaption">
												{data.image_left_caption.text === `Dummy` ? null : data.image_left_caption.text}
											</span>
											<DualImageHover
												image1={data.image_left}
												image2={data.image_left_2}
											/>

											<div className="sliderColorsShapes__MobileSWiper showOnTablet">
												{leftSlides.length > 1 ? <ImageSliderSwiperjs slides={leftSlides} /> : <div
													className="ProductCategories__Section ProductCategories__FullWidthImage"
													key={productId + '_' + slice.id}
												>
													<Picture
														src={data.image_left.fluid.src}
														srcSet={data.image_left.fluid.srcSet}
														alt={data.image_left.fluid.alt}
														sizes="94.44vw"
													/>
												</div>}
											</div>
										</div>
										<div className="ProductCategories__SideBySideImageContainer">
											<span className="ProductCategories__SideBySideImageCaptionTop">
												{data.image_right_top_caption?.text}
											</span>
											<span className="ProductCategories__SideBySideImageCaption">
												{data.image_right_caption.text === `Dummy` ? null : data.image_right_caption.text}
											</span>
											<DualImageHover
												image1={data.image_right}
												image2={data.image_right_2}
											/>
											<div className="sliderColorsShapes__MobileSWiper showOnTablet">
											{leftSlides.length > 1 ? <ImageSliderSwiperjs slides={rightSlides} /> : <div
													className="ProductCategories__Section ProductCategories__FullWidthImage"
													key={productId + '_' + slice.id}
												>
													<Picture
														src={data.image_right.fluid.src}
														srcSet={data.image_right.fluid.srcSet}
														alt={data.image_right.alt}
														sizes="94.44vw"
													/>
												</div>}
											</div>
										</div>
									</div>
								)
							}
							else if (slice.slice_type === 'subtitle_and_paragraph') {
								return (
									<div
										className="ProductCategories__Section ProductCategories__SubtitleAndParagraph"
										key={productId + '_' + slice.id}
									>
										<h4 className="ProductCategories__SubtitleAndParagraphSubtitle">
											{slice.primary.subtitle.text}
										</h4>
										<div className="ProductCategories__SubtitleAndParagraphParagraph">
											<RichText render={slice.primary.paragraph.raw} />
										</div>
									</div>
								)
							}
							else if (slice.slice_type === 'design_customization' && slice.primary.is_activated) {
								return (
									<div
										className="ProductCategories__Section ProductCategories__DesignCustomization"
										key={productId + '_' + slice.id}
									>
										 <DesignCustomization
											introImage={slice.primary.intro_image}
											items={slice.items}
											isActivated={slice.primary.is_activated}
											// TO DO: weird fix for the QA, might need refactor if the client settle on a decision
											// see Design customization props for more explanation on this.
											forceOverlayOnColorTypeImages={category.title.text === 'Fabrik'}
										/>
									</div>
								)
							}
							else if (slice.slice_type === 'images_slider') {

								return (
									<div
										className="ProductCategories__Section ProductCategories__DesignCustomization"
										key={productId + '_' + slice.id}
									>
										<ColorsGlazesShapes sliderImageSliceData = {slice}></ColorsGlazesShapes>
									</div>
								)
							}
							return <div key={productId + '_' + slice.id}></div>;
						})}
					</div>
				</div>
			</div>
			<Footer footer={prismicFooter.data} showImage={false} />
		</div>
	)
}

export const productDetailQuery = graphql`
query ProductDetailQueryByUid($uid: String!) {
	prismicProductCategory(uid: {eq: $uid}) {
		id
		uid
		data {
		  title {
			text
		  }
		  meta_title {
			text
		  }
		  meta_description {
			text
		  }
		  meta_keywords {
			text
		  }
		  overview_title {
			text
		  }
		  title_description {
			raw
		  }
		  download_center_button
		  subheading {
			text
		  }
		  subheading_description {
			raw
		  }
		  image {
			alt
			fluid {
			  src
			  srcSet
			}
		  }
		  body {
			... on PrismicProductCategoryBodyParagraphs {
			  id
			  primary {
				paragraph {
				  raw
				}
			  }
			  slice_type
			}
			... on PrismicProductCategoryBodySideBySideImage {
			  id
			  slice_type
			  primary {
				image_left {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
				image_left_2 {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
				image_left_caption {
				  text
				}
				image_left_top_caption{
					text
				}
				image_right {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
				image_right_2 {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
				image_right_caption {
				  text
				}
				image_right_top_caption{
					text
				}
			  }
			}
			... on PrismicProductCategoryBodyFullWidthImage {
			  id
			  slice_type
			  primary {
				image1 {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
			  }
			}
			... on PrismicProductCategoryBodyButton {
			  id
			  slice_type
			  primary {
				text {
				  text
				}
				link {
				  type
				  uid
				  url
				}
			  }
			}
			... on PrismicProductCategoryBodySubtitleAndParagraph {
			  id
			  slice_type
			  primary {
				subtitle {
				  text
				}
				paragraph {
				  raw
				}
			  }
			}
			... on PrismicProductCategoryBodyDesignCustomization {
			  id
			  slice_type
			  primary {
				is_activated
				intro_image {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
			  }
			  items {
				type
				label
				image1 {
				  alt
				  fluid {
					src
					srcSet
				  }
				}
			  }
			}
			... on PrismicProductCategoryBodyImagesSlider {
			  id
			  slice_type
			  id
			  slice_type
			  items {
				... on PrismicProductCategoryBodyImagesSliderItemType {
				  image {
					... on PrismicImageType {
					  url
					  fluid {
						src
						srcSet
					  }
					}
				  }
				  title {
					text
				  }
				}
			  }
			  primary {
				... on PrismicProductCategoryBodyImagesSliderPrimaryType {
				  slider_title {
					... on PrismicStructuredTextType {
					  text
					}
				  }
				  slider_description {
					... on PrismicStructuredTextType {
					  text
					}
				  }
				  button_name {
					... on PrismicStructuredTextType {
					  text
					}
				  }
				  button_link {
					... on PrismicStructuredTextType {
					  text
					}
				  }
				}
			  }
			}
		  }
		}
	  }
	  prismicNavigation {
		...HeaderQuery
	  }
	  prismicFooter {
		...FooterQuery
	  }
	}
	
`

export default ProductCategoryDetails;